<template>
  <div class="ui grid container-3" id="acctyps">
    <div class="content-details">
      <h1>Get Verified <span>and Unlock All Features & Services</span> </h1>
      <p>Downloading and registering in the TayoCash app automatically makes you a TayoCash Basic Member.<br> Verify your account to maximize what you can do with your TayoCash e-wallet!</p>

      <div class="ui four column grid table-style">
        <div class="row">
          <div class="column only">
            <div class="content first">
              <div class="meta wallet">
                <span>Wallet Limit</span>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="content">
              <div class="header">BASIC</div>
              <div class="meta">
                <span>P50,000/month</span>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="content">
              <div class="header">SEMI-VERIFIED</div>
              <div class="meta">
                <span>P100,000/year</span>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="content">
              <div class="header">FULLY VERIFIED</div>
              <div class="meta">
                <span>P100,000/month</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <div class="content first">
              <div class="meta">
                <span>Features Available</span>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="content">
              <div class="meta">
                <span>Cash In, Pay Bills, Buy Load</span>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="content">
              <div class="meta">
                <span>
                  Cash In, Cash Out, Pay Bills, Buy Load,
                  Fund Transfer, Pay QR,
                  Remittance
                </span>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="content">
              <div class="meta">
                <span>
                  Cash In, Cash Out, Pay Bills, Buy Load,
                  Fund Transfer, Pay QR,
                  Remittance, <br> Be a Partner or Merchant
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <div class="content first">
              <div class="meta">
                <span>
                  Registration Requirements
                </span>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="content">
              <div class="meta">
                <span>Name and mobile number</span>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="content">
              <div class="meta">
                <span>
                  Name, Mobile Number, additional personal information, and video selfie</span>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="content">
              <div class="meta">
                <span>
                  All requirements for
                  semi-verified
                  plus a government-issued ID
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-content">
        <div class="ui grid three column row">
          <div class="column">
            <div class="content">
              <div class="title">BASIC</div>
              <span>P50,000/month</span>
              <p>Cash In, Pay Bills, Buy Load</p>
              <p>Name and mobile number</p>
            </div>
          </div>
          <div class="column">
            <div class="content">
              <div class="title">SEMI-VERIFIED</div>
              <p>P100,000/year</p>
              <p>Cash In, Cash Out, Pay Bills, Buy Load, Fund Transfer, Pay QR, Remittance</p>
              <p>Name, Mobile Number, additional personal information, and video selfie</p>
            </div>
          </div>
          <div class="column">
            <div class="content">
              <div class="title">FULLY VERIFIED</div>
              <p>P100,000/month</p>
              <p>Cash In, Cash Out, Pay Bills, Buy Load, Fund Transfer, Pay QR, Remittance, Be a Partner or Merchant</p>
              <p>All requirements for semi-verified plus a government-issued ID</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'content3'
}
</script>

<style scoped lang='scss'></style>
